<template>
  <div>
    <v-row>
      <SelectBox
        label="상품공통 적용"
        selectStyleWidth="width: 100%"
        :value="commonBookingType"
        :items="itemsBookingType"
        :multiple="false"
        @changeSelect="changeSelectBookingType"
      />
    </v-row>
    <v-row>
      <RadioComps
        label="상품별 적용"
        :prosSelected="useTypeValue"
        :items="useTypeItems"
        @changeType="changeTypeUseYN"
      />
    </v-row>
    <v-row>
      <ProductBookingTypeTable
        ref="refProductBookingType"
        v-if="useTypeValue === 'Y'"
        :clinicData="clinicData"
      />
    </v-row>
  </div>
</template>
<script>
import ProductBookingTypeTable from "@/components/commonV2/ui/ProductBookingTypeTable.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const formatService = require("@/utils/format.js");
export default {
  components: { SelectBox, RadioComps, ProductBookingTypeTable },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  watch: {
    clinicData(val) {
      if (val === null) return;
      this.commonBookingType = val.bookingTypeValue;
      this.useTypeValue = val.productTypeUseYN;
      // this.changeTypeUseYN(val.productTypeUseYN);
    },
  },
  data() {
    return {
      commonBookingType: "",
      itemsBookingType: formatService.itemsBookingType(),
      useTypeValue: "N",
      useTypeItems: formatService.useTypeItems(),
    };
  },
  methods: {
    _reset() {
      this.useTypeValue = this.clinicData.productTypeUseYN;
      this.commonBookingType = this.clinicData.bookingTypeValue;
    },
    changeSelectBookingType(e) {
      this.commonBookingType = e;
      this.clinicData.bookingTypeValue = e;
    },
    changeTypeUseYN(e) {
      this.useTypeValue = e;
      this.clinicData.productTypeUseYN = e;
    },
  },
};
</script>
<style lang="">
</style>