<template>
  <v-dialog v-model="visible" max-width="600px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> 병의원 예약가능일정 설정 </v-card-title>

      <BookingSetting ref="refBookingSetting" :clinicData="clinicData" />

      <BtnBox :isShowBtnProduct="false" @save="save" />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Header from "./comp/Header.vue";
import BtnBox from "./comp/BtnBox.vue";
import BookingSetting from "@/_kimCare/clinic/basicTabs/booking/setting/BookingSetting.vue";
const modelService = require("@/utils/dataModel");

export default {
  components: {
    Header,
    BtnBox,
    BookingSetting,
  },
  data() {
    return {
      visible: false,
      clinicData: null, //modelService.hospitalModel(),
      reqData: modelService.hospitalBookingTypeModel(),
    };
  },
  methods: {
    Open(clinicData) {
      console.log("clinicData", clinicData);
      this.visible = true;
      this.clinicData = clinicData;
      setTimeout(() => {
        this.$refs.refBookingSetting._reset();
      }, 300);
    },
    close() {
      this.visible = false;
    },
    async save() {
      console.log("", this.clinicData);
      const $h = this.$helper;
      // 필수 확인
      if (this.clinicData.productTypeUseYN === "N") {
        if (this.clinicData.bookingTypeValue === "") {
          const msg = "상품공통 적용값을 선택해주세요.";
          $h.showTostMessage("", msg, "info");
          return;
        }
      }

      // 상품별 적용시
      if (this.clinicData.productTypeUseYN === "Y") {
        if (this.clinicData.productTypes.length === 0) {
          const msg = "병원상셍정보>상품정보 먼저 입력해주세요.";
          $h.showTostMessage("", msg, "info");
          return;
        }
      }

      this.clinicData.endpoint = "hospitalBookingType";
      await this.saveDataQuery(this.clinicData).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", $h.commonMessage("S"), "success");
        this.visible = false;
        this.$emit("complete", res);
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>
<style lang="">
</style>