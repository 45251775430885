<template>
  <v-row>
    <v-col>
      <DataTable
        ref="DataTable"
        propsHeight="300px"
        :isFixHeader="true"
        :tableKey="'rowSeq'"
        :perPage="10000"
        :footerHide="true"
        :singleSelect="true"
        :showSelect="false"
        :columns="dataColumns"
        :inputData="clinicData.productTypes"
        @clickRow="clickRow"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
import DataTable from "@/components/commonV2/table/DataTable.vue";
const formatService = require("@/utils/format.js");

export default {
  components: { DataTable },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      dataColumns: formatService.productTypeTableColumnsV1(),
    };
  },
  watch: {
    clinicData(val) {
      if (val === null) return;
      this.tableData = val.productTypes;
    },
  },
  methods: {
    clickRow(e) {},
  },
};
</script>
<style lang="">
</style>