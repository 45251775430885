<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">
        {{ $t("menu.userBooking") }}
        <!-- 도움말 -->
        <HelpMsg :msgType="helpMsg" />
      </v-card-text>
    </v-col>
    <v-col cols="12" class="ma-0 pa-0">
      <SearchTopUIComp
        :placeholder="$t('common.component.searchTop.searchInputWord2')"
        :propsSearchWorld="reqData.searchVal"
        @changeText="changeText"
        @changeSearchWordComplete="changeSearchWordComplete"
      />
    </v-col>
    <!-- 상담 / 견적 까지는 모든병원에서 볼수 있음 -> 내용작성을 위해서 병원이 선택되어야 함 -->
    <v-col cols="12" class="ma-0 pa-0">
      <div class="uiSelectBox">
        <SelectBoxClinic @updateSelect="updateSelectClinic" />
      </div>
    </v-col>

    <!-- 상담리스트 -->
    <v-col cols="12">
      <UserBookingList :patientData="patientData" />
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SearchTopUIComp from "@/components/commonV2/ui/SearchTopUIComp.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import UserBookingList from "@/_kimCare/clinic/basicTabs/booking/userBooking/UserBookingList.vue";
import HelpMsg from "@/components/commonV2/helpMsg/HelpMsg.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");

export default {
  components: {
    SearchTopUIComp,
    SelectBoxClinic,
    UserBookingList,
    HelpMsg,
  },
  data() {
    return {
      patientData: [],
      helpMsg: this.$t("helpMsg.clinic"),
      reqData: modelService.reqDataModel(),
      propsDate: [this.$moment().subtract(30, "year"), this.$moment()],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**** 검색어 ***/
    changeSearchWordComplete(e) {
      this.reqData.searchVal = e;
      this.getData();
    },
    changeText(e) {
      this.reqData.searchVal = e;
    },
    /**** 클리닉 선택 ***/
    updateSelectClinic(e) {
      this.patientData.forEach((element) => {
        element.hospital_id = e === "" ? 0 : e;
      });
    },
    /**** 데이터읽기 ***/
    async getData() {
      this.clinicData = [];
      const $h = this.$helper;
      this.reqData.fromDay = $h.changeDateToString(this.propsDate[0], false);
      this.reqData.toDay = $h.changeDateToString(this.propsDate[1], false);
      this.reqData.endpoint = "patient";
      this.reqData.patientType = "provisional"; // 예정환자
      await this.getDataQuery(this.reqData).then((resP) => {
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            isUserTable: true,
            nameFull: `${$h.changeNameFullDp(
              item.fname,
              item.mname,
              item.lname
            )}(${item.user_id})`,
            genderShort: $h.changeGenderShortForDp(item.gender),
            age: $h.changeDOBToAgeForDp(item.dob),
            ccmCareTimeLabel: $h.secondsToTime(item.ccmcareTime, true),
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            updDateLabel: $h.changeDateToStringForDp(item.updDate),
            useYNLabel: $h.memberTypeLbl(item.activated),
            signupPathLabel: $h.signupPathLabel(item.signupPath),
            isPhoto: $h.isPhotoChecker(item.userPhotoImg),
            dpDateLbl: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            patientMemo: $h.patientMemo(item.memo),
            cardTitle: `${item.name}(${item.gender})`,
            cardSubTitle: `${$h.changeDOBToAgeForDp(item.dob)} / ${
              item.serviceType
            } 
            -> ${$h.secondsToTime(item.ccmcareTime, true)}`,
            cardImg: $h.makeUserAvatarImg(
              item.userPhotoImg,
              $h.isPhotoChecker(item.userPhotoImg),
              item.gender
            ),
            cardRegistrationDate: $h.changeDateToStringForDp(
              item.regDate,
              true
            ),
          }));
        }
        this.patientData = res;
        // 병원로그인인 경우
        if (this.meInfo !== null && this.meInfo.userType !== "super") {
          this.patientData.forEach((element) => {
            element.hospital_id = this.meInfo.hospital_id;
          });
        }
        // console.log("this.patientData", this.patientData);
      });
    },
    ...mapActions(["getDataQuery"]),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>
<style lang="">
</style>